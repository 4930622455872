
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}
.border-all-1{
  border-width: 1px;
  border-style: solid;
}

.svg-primary{
  path{
    fill: red;
  }
}

.navbar-left .navbar-brand{
  .logo-light, .logo-dark{
    width:auto;
    height:35px;
  }
}

header .logo-light{
  width:auto;
  height:50px;

}

.homepage-hero{
  background-image: linear-gradient(120deg, #89f7fe 0%, darken($primary, 20%) 100%);
}

@include media-breakpoint-down(xs) {
  header {
    h1 {
      height:2.5em;
    }
  }
}
@include media-breakpoint-up(sm) {
  header{
    h1{
    }
    .xs-break{
      display:none;
    }
  }
}
@include media-breakpoint-up(sm) {
  body:not(.header-scrolled, .navbar-persist) .navbar {
    .navbar-mobile, a.btn {
      display: none;
    }

    > .container {
      justify-content: center;
    }

    .navbar-left .navbar-brand {
      .logo-light, .logo-dark {
        height: 65px;
        display:none;
        margin-top: 40px;
      }
    }
  }
}

nav.navbar.stick{
  //box-shadow:none;
  border-bottom:1px solid rgba(0,0,0,0.1);
}

.poobear{
  position:absolute;
  top:-1000px;
  left:-10000px;
  opacity:0.01;
  height:1px;
}

@include media-breakpoint-down(xs) {
  .constellation{
    display: none;
  }
}

ul.features-columns{

  @include media-breakpoint-up(sm) {
    column-count: 2;
  }
  @include media-breakpoint-up(md) {
    column-count: 3;
  }
  @include media-breakpoint-up(lg) {
    column-count: 4;
  }

  padding-left: 0;
  margin: 0;
  list-style: none;

  li{
    text-align:left;
    list-style: none;
    position: relative;
    padding-left: 20px;
    font-size: 1.1em;
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 14px;
      height: 10px;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0iI2M0ZDYwMCI+PHBhdGggZD0iTTEzLjEgMi40YS45LjkgMCAwIDEtLjMuNkw2LjEgOS43bC0uNS4yLS41LS4yLTMuOS0zLjlhLjkuOSAwIDAgMS0uMy0uNi45LjkgMCAwIDEgLjMtLjVsMS0xYS45LjkgMCAwIDEgLjYtLjNjLjIgMCAuMy4xLjUuM0w1LjYgNiAxMC43LjhsLjUtLjIuNi4yIDEgMS4xYS41LjUgMCAwIDEgLjMuNXoiLz48L3N2Zz4=") no-repeat;
    }
  }
}


.text-shadow{
  text-shadow: 0px 0px rgba(0,0,0,0.75);
}


.modal.apply-form{
  iframe{
    width:100%;
    height:80vh;
    border:none;
    background: transparent;
  }
}

.font-title-large{
  font-size: 3.5em;
}


form input.ng-invalid.ng-touched, form.is-attempted input.ng-invalid{
  @extend .is-invalid;
  &.ng-invalid-required{
    &:after{
      content: "This field is required.";
      @extend .invalid-feedback;
    }
  }
}

.blog-posts > :nth-child(odd){
  background:#f5f5f5;
}